import * as React from "react"
import Seo from "../components/seo"
import "../styles/styles.sass"
import { AssetLogo } from "../components/assets"

const IndexPage = () => {
  return (
  <main className="mercuryView homeView">
    <Seo
      title="Innovat Studio"
    />

    <div className="mercuryContent">
      <article className="comingSoon">
        <AssetLogo className="studioLogo"/>
        <h1 className="studioName">Innovat Studio</h1>
        <p className="studioSubtitle">Coming soon!</p>
      </article>

    </div>
    
  </main>
  )
}

export default IndexPage
